<template>
  <div class="login login-bg">
    <div class="side-img-wrapper">
      <img class="side-img" src="../../assets/banner-future.jpg" alt="">
    </div>
    <div class="login-content-wrapper">
      <div class="login-content">
        <img class="logo" src="../../assets/totem-logo.png" alt="Logo">
        <p>You are already logged in, but you<br />lack admin permissions.</p>
        <form name="login" onsubmit="return false;">
          <div class="field field-icon disabled">
            <i class="far fa-user"></i>
            <input type="text" name="user" id="login-user" v-model="username" disabled="disabled">
          </div>
          <div class="submit">
            <div class="login-button" @click="signout">
              <input type="submit" value="Log Out">
              <i class="far fa-long-arrow-left"></i>
            </div>
          </div>
          <router-link to="/">Return to website</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginSojourn",
  inject: ['createNotification'],
  computed: {
    username() {
      return this.$store.state.user ? this.$store.state.user.email : ''
    }
  },
  methods: {
    signout() {
      this.$store.dispatch("logout").then(result => {
        if (result === true) {
          this.$router.push('/')
        } else {
          this.createNotification({
            type: 'error',
            title: 'Error during sign out',
            message: `An error occured while signing out: ${result}`
          })
        }
      }).catch(error => {
        console.log(`Signout error that should've been caught in the store action: ${error}`)
      })
    }
  },
}
</script>

<style lang="scss">
.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  

  .side-img-wrapper {
    position: relative;
    width: 50vw;
    height: 100vh;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #292835;
      opacity: .85;
    }

    .side-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &-content {
    color: #817F98;
    width: 340px;

    & > * + * {
      margin-top: 2rem;
    }

    .logo {
      width: 70px;
    }

    .field {
      position: relative;
      margin-bottom: 1rem;

      &-icon i {
        display: flex;
        align-items: center;
        position: absolute;
        left: 1.5rem;
        top: 0;
        height: 100%;
      }

      &-icon.disabled i {
        color: #fff;
      }
    }

    input {
      outline: none;
      color: #fff;
      font: 17px "Rubik", sans-serif;
      transition: background-color 200ms ease, border-color 200ms ease;
    }

    input[type=text], input[type=password] {
      background-color: transparent;
      border: 2px solid #817F98;
      padding: .75rem 2rem;
      padding-left: 3rem;
      border-radius: 1000px;

      &::placeholder {
        color: #817F98;
      }

      &:focus, &:hover {
        border-color: #fff;
      }
    }

    .disabled input[type=text], input[type=password] {
      background-color: #817F98;
      color: #fff;

      &::placeholder {
        color: #817F98;
      }

      &:focus, &:hover {
        border-color: #817F98;
      }
    }

    .submit {
      display: flex;
      margin-top: 2rem;

      .login-button {
        position: relative;
        width: 180px;

        input[type=submit] {
          width: 100%;
          padding: .75rem 2rem;
          background-color: var(--color-purple);
          border: none;
          border-radius: 1000px;
          text-align: left;
          cursor: pointer;

          &:hover {
            background-color: var(--color-purple-light);
          }
        }
        
        i {
          display: flex;
          align-items: center;
          position: absolute;
          top: 0;
          right: 1.5rem;
          height: 100%;
          color: #fff;
        }
      }

      .google-login {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 44px;
        margin-left: 1rem;
        background-color: #fff;
        border-radius: 1000px;
        cursor: pointer;
      }
    }

    a {
      display: inline-block;
      color: #817F98;
      margin-top: 5rem;
      transition: color 200ms ease;

      &:hover, &:focus {
        color: var(--color-purple-lightest);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .login {
    height: 100vh;
    grid-template-columns: 1fr;

    .side-img-wrapper {
      display: none;
    }
    
    .login-content {
      width: auto;
      text-align: center;
    }
  }
}
</style>